<template>
  <admin-data-card :title="'Cas patients renseignés'" :dataNumber="sumForms">
    <bar-graph
      :hint="'Cas patients renseignés'"
      :headers="formulaireDates"
      :values="formulaireCounts"
    ></bar-graph>
  </admin-data-card>
</template>

<script>
import FormulaireService from "../../../services/formulaire.service";

export default {
  name: "FormulaireCountCard",

  components: {
    "admin-data-card": () => import("../../../core/card/AdminDataCard"),
    "bar-graph": () => import("../../../core/graphs/BarGraph"),
  },

  data() {
    return {
      sumForms: 0,
      formulaireDates: [],
      formulaireCounts: [],
    };
  },

  mounted() {
    // Récupration des aggrégats utilisateur
    FormulaireService.getFormulaireCountByDate().then(
      (response) => {
        let users = response.data;

        this.formulaireDates = users.map((a) => a._id);
        this.formulaireCounts = users.map((a) => a.count);
      },
      (error) => {
        console.error(error.response);
      }
    );

    // Récupréation du total
    FormulaireService.countPatientsInfo().then(
      (response) => {
        this.sumForms = response.data.patientsNumber;
      },
      (error) => {
        console.error(error.response);
      }
    );
  },
};
</script>
